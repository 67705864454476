import React from "react";
import {
  Nav,
  Card,
  BarChart,
  Table,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment";
import jourFeries from "@socialgouv/jours-feries";

import { colors, getColors } from "../../utils/colors";
import { getPercentageValue } from "../../utils/general";

import axiosConfig from "../../utils/axiosConfig";

import AuthContext from "../../context/AuthContext";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalSendEmailIxPerience: 0,
      totalReadEmailIxPerience: 0,
      totalRatioEmailIxPerience: 0,
      sendEmailIxPerience: [],
      readEmailIxPerience: [],
      ratioEmailIxPerience: [],
      timeScheduledEmailIxPerience: [],
      timeReadEmailIxPerience: [],
      newFolderPilotageCabinet: [],
      newFolderPixTransfer: [],
      newFolderUserPixTransfer: [],
      percentageFolderUserPixTransfer: [],
      newFolderEvalCar: [],
      newFolderUserEvalCar: [],
      percentageFolderUserEvalCar: [],
      newFolderRedaxion: [],
      dataMaileva: [],
      dataParts: {},
      dataPQE: {},
      dataRegions: [],
      tabsIdx: 0,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getStatisticIxPerience();
    this.getNewFolderPilotageCabinet();
    this.getNewFolderPixTransfer();
    this.getNewFolderUserPixTransfer();
    this.getPercentageFolderUserPixTransfer();
    this.getNewFolderEvalCar();
    this.getNewFolderUserEvalCar();
    this.getPercentageFolderUserEvalCar();
    this.getNewFolderRedaxion();
    this.getDataMaileva();
    this.getDataParts();
    this.getDataPQE();
    this.getDataRegions();
  }

  formatNumber = (value) => {
    if (value) {
      return new Intl.NumberFormat("cs").format(value);
    } else {
      return 0;
    }
  };

  getStatisticIxPerience = () => {
    axiosConfig("home/get-statistic-ixperience").then((res) => {
      if (res.data.success) {
        this.setState({
          totalSendEmailIxPerience: res.data.totalSend,
          totalReadEmailIxPerience: res.data.totalRead,
          totalRatioEmailIxPerience: res.data.totalRatio,
          sendEmailIxPerience: res.data.send,
          readEmailIxPerience: res.data.read,
          ratioEmailIxPerience: res.data.ratio,
          timeScheduledEmailIxPerience: res.data.timeScheduled,
          timeReadEmailIxPerience: res.data.timeRead,
        });
      } else {
        this.setState({
          totalSendEmailIxPerience: 0,
          totalReadEmailIxPerience: 0,
          totalRatioEmailIxPerience: 0,
          sendEmailIxPerience: [],
          readEmailIxPerience: [],
          ratioEmailIxPerience: [],
          timeScheduledEmailIxPerience: [],
          timeReadEmailIxPerience: [],
        });
      }
    });
  };

  getNewFolderPilotageCabinet = (region = null) => {
    axiosConfig("home/get-new-folder-pilotage-cabinet", {
      data: { region },
    }).then((res) => {
      if (res.data.success) {
        this.setState({ newFolderPilotageCabinet: res.data.data });
      } else {
        this.setState({
          newFolderPilotageCabinet: [],
        });
      }
    });
  };

  getNewFolderPixTransfer = () => {
    axiosConfig("home/get-new-folder-pixtransfer").then((res) => {
      if (res.data.success) {
        this.setState({ newFolderPixTransfer: res.data.data });
      } else {
        this.setState({
          newFolderPixTransfer: [],
        });
      }
    });
  };

  getNewFolderUserPixTransfer = () => {
    axiosConfig("home/get-new-folder-user-pixtransfer").then((res) => {
      if (res.data.success) {
        let newFolderUserPixTransfer = [];

        let arrayColor = [...getColors(0), ...getColors(1), ...getColors(2)];
        let idx = 0;

        Object.keys(res.data.data).forEach((key) => {
          newFolderUserPixTransfer.push(res.data.data[key]);
          newFolderUserPixTransfer[idx].type = "line";
          newFolderUserPixTransfer[idx].color = arrayColor[idx];
          idx++;
        });

        this.setState({ newFolderUserPixTransfer });
      } else {
        this.setState({
          newFolderUserPixTransfer: [],
        });
      }
    });
  };

  getPercentageFolderUserPixTransfer = () => {
    axiosConfig("home/get-percentage-folder-user-pixtransfer").then((res) => {
      if (res.data.success) {
        let percentageFolderUserPixTransfer = [];

        let arrayColor = [...getColors(0), ...getColors(1), ...getColors(2)];
        let idx = 0;

        Object.keys(res.data.data).forEach((key) => {
          percentageFolderUserPixTransfer.push(res.data.data[key]);
          percentageFolderUserPixTransfer[idx].type = "line";
          percentageFolderUserPixTransfer[idx].color = arrayColor[idx];
          idx++;
        });

        this.setState({ percentageFolderUserPixTransfer });
      } else {
        this.setState({
          percentageFolderUserPixTransfer: [],
        });
      }
    });
  };

  getNewFolderEvalCar = () => {
    axiosConfig("home/get-new-folder-evalcar").then((res) => {
      if (res.data.success) {
        this.setState({ newFolderEvalCar: res.data.data });
      } else {
        this.setState({
          newFolderEvalCar: [],
        });
      }
    });
  };

  getNewFolderUserEvalCar = () => {
    axiosConfig("home/get-new-folder-user-evalcar").then((res) => {
      if (res.data.success) {
        let newFolderUserEvalCar = [];

        let arrayColor = [...getColors(0), ...getColors(1), ...getColors(2)];
        let idx = 0;

        Object.keys(res.data.data).forEach((key) => {
          newFolderUserEvalCar.push(res.data.data[key]);
          newFolderUserEvalCar[idx].type = "line";
          newFolderUserEvalCar[idx].color = arrayColor[idx];
          idx++;
        });

        this.setState({ newFolderUserEvalCar });
      } else {
        this.setState({
          newFolderUserEvalCar: [],
        });
      }
    });
  };

  getPercentageFolderUserEvalCar = () => {
    axiosConfig("home/get-percentage-folder-user-evalcar").then((res) => {
      if (res.data.success) {
        let percentageFolderUserEvalCar = [];

        let arrayColor = [...getColors(0), ...getColors(1), ...getColors(2)];
        let idx = 0;

        Object.keys(res.data.data).forEach((key) => {
          percentageFolderUserEvalCar.push(res.data.data[key]);
          percentageFolderUserEvalCar[idx].type = "line";
          percentageFolderUserEvalCar[idx].color = arrayColor[idx];
          idx++;
        });

        this.setState({ percentageFolderUserEvalCar });
      } else {
        this.setState({
          percentageFolderUserEvalCar: [],
        });
      }
    });
  };

  getNewFolderRedaxion = () => {
    axiosConfig("home/get-new-folder-redaxion").then((res) => {
      if (res.data.success) {
        this.setState({ newFolderRedaxion: res.data.data });
      } else {
        this.setState({
          newFolderRedaxion: [],
        });
      }
    });
  };

  getDataMaileva = () => {
    axiosConfig("home/get-data-maileva").then((res) => {
      if (res.data.success) {
        this.setState({ dataMaileva: res.data.data });
      } else {
        this.setState({
          dataMaileva: [],
        });
      }
    });
  };

  getDataParts = () => {
    axiosConfig("home/get-data-parts").then((res) => {
      if (res.data.success) {
        this.setState({ dataParts: res.data.data });
      } else {
        this.setState({
          dataParts: {},
        });
      }
    });
  };

  getDataPQE = () => {
    axiosConfig("home/get-data-pqe").then((res) => {
      if (res.data.success) {
        this.setState({ dataPQE: res.data.data });
      } else {
        this.setState({
          dataPQE: {},
        });
      }
    });
  };

  getDataRegions = () => {
    axiosConfig("home/get-data-regions").then((res) => {
      if (res.data.success) {
        this.setState({ dataRegions: res.data.data });
      } else {
        this.setState({
          dataRegions: [],
        });
      }
    });
  };

  checkIsWeek = (date) => {
    let dates = jourFeries(moment(date, "DD/MM/YYYY").format("YYYY"));

    const datesHoliday = Object.keys(dates).map((key) => {
      return moment(dates[key]).format("DD/MM/YYYY");
    });

    if (
      ![0, 6].includes(moment(date, "DD/MM/YYYY").day()) &&
      !datesHoliday.includes(date)
    ) {
      return true;
    } else {
      return false;
    }
  };

  getDate = (day) => {
    let days = [];

    for (let index = day - 1; index >= 0; index--) {
      const current = moment().subtract(index, "days");
      days.push(current.format("DD/MM/YYYY"));
    }

    return days;
  };

  render() {
    const dateIxPerience = this.getDate(61);
    const checkIsWeekIxPerience = dateIxPerience.map((date) =>
      this.checkIsWeek(date)
    );
    const dateDefault = this.getDate(41);
    const checkIsWeekDefault = dateDefault.map((date) =>
      this.checkIsWeek(date)
    );

    return (
      <>
        <Nav className="mb-20" title="Tableau de bord" />
        <Row>
          {this.context.isEvalCar ? (
            <Col xs={12}>
              <Card
                title={`Envoi / Lecture mail IxPérience (${this.formatNumber(
                  this.state.totalReadEmailIxPerience
                )}/${this.formatNumber(
                  this.state.totalSendEmailIxPerience
                )} - ${this.state.totalRatioEmailIxPerience}%)`}
                className={"mt-10 mt-30-xl"}
              >
                <BarChart
                  title=""
                  height={360}
                  labels={dateIxPerience.filter(
                    (date, idx) => checkIsWeekIxPerience[idx]
                  )}
                  digits={0}
                  dataSet={[
                    {
                      yAxisID: "D",
                      label: "Durée moyenne d'envoi (Minutes)",
                      type: "line",
                      data: this.state.timeScheduledEmailIxPerience.filter(
                        (element, idx) => checkIsWeekIxPerience[idx]
                      ),
                      color: colors.red[0],
                    },
                    {
                      yAxisID: "C",
                      label: "Durée moyenne ouverture (Heures)",
                      type: "line",
                      data: this.state.timeReadEmailIxPerience.filter(
                        (element, idx) => checkIsWeekIxPerience[idx]
                      ),
                      color: colors.purple[0],
                    },
                    {
                      yAxisID: "B",
                      label: "Ratio (%) Lecture / Envoi",
                      format: "percentage",
                      type: "line",
                      data: this.state.ratioEmailIxPerience.filter(
                        (element, idx) => checkIsWeekIxPerience[idx]
                      ),
                      color: colors.orange[0],
                    },
                    {
                      yAxisID: "A",
                      label: "Envoi",
                      type: "bar",
                      data: this.state.sendEmailIxPerience.filter(
                        (element, idx) => checkIsWeekIxPerience[idx]
                      ),
                      color: colors.blue[0],
                    },
                    {
                      yAxisID: "A",
                      label: "Lecture",
                      type: "bar",
                      data: this.state.readEmailIxPerience.filter(
                        (element, idx) => checkIsWeekIxPerience[idx]
                      ),
                      color: colors.green[0],
                    },
                  ]}
                  yAxes={[
                    {
                      id: "A",
                      ticks: {
                        display: false,
                        beginAtZero: true,
                      },
                      gridLines: {
                        display: true,
                      },
                    },
                    {
                      id: "B",
                      ticks: {
                        display: false,
                        beginAtZero: true,
                      },
                      gridLines: {
                        display: false,
                      },
                    },
                    {
                      id: "C",
                      ticks: {
                        display: false,
                        beginAtZero: true,
                      },
                      gridLines: {
                        display: false,
                      },
                    },
                    {
                      id: "D",
                      ticks: {
                        display: false,
                        beginAtZero: true,
                      },
                      gridLines: {
                        display: false,
                      },
                    },
                  ]}
                  xAxes={[
                    {
                      gridLines: {
                        display: false,
                      },
                    },
                  ]}
                />
              </Card>
            </Col>
          ) : null}
          <Col xs={12}>
            <TabsCustom>
              <TabsCustomHeader className="mt-10">
                {[
                  { regionName: "Toutes les régions" },
                  ...this.state.dataRegions,
                ].map((element, idx) => {
                  return (
                    <TabsCustomMenu
                      key={idx}
                      title={element.regionName}
                      active={this.state.tabsIdx === idx}
                      onClick={() =>
                        this.setState(
                          {
                            tabsIdx: idx,
                            newFolderPilotageCabinet: [],
                          },
                          () =>
                            this.getNewFolderPilotageCabinet(element.regionId)
                        )
                      }
                    />
                  );
                })}
              </TabsCustomHeader>
              <TabsCustomBody noCard>
                <TabsCustomItem active={true} className="overflow-x-auto">
                  <Card
                    title="Nouveaux dossiers Pilotage Cabinet"
                    className={"mt-10 mt-30-xl"}
                  >
                    <BarChart
                      title=""
                      height={300}
                      labels={dateDefault.filter(
                        (date, idx) => checkIsWeekDefault[idx]
                      )}
                      digits={0}
                      dataSet={[
                        {
                          label: "Nouveaux dossiers",
                          type: "line",
                          data: this.state.newFolderPilotageCabinet.filter(
                            (element, idx) => checkIsWeekDefault[idx]
                          ),
                          color: colors.blue[0],
                        },
                      ]}
                    />
                  </Card>
                </TabsCustomItem>
              </TabsCustomBody>
            </TabsCustom>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Card
              title="Nouvelles photos PixTransfer"
              className={"mt-10 mt-30-xl"}
            >
              <BarChart
                title=""
                height={460}
                labels={dateDefault.filter(
                  (date, idx) => checkIsWeekDefault[idx]
                )}
                digits={0}
                dataSet={[
                  {
                    label: "Nouvelles photos",
                    type: "line",
                    data: this.state.newFolderPixTransfer.filter(
                      (element, idx) => checkIsWeekDefault[idx]
                    ),
                    color: colors.blue[0],
                  },
                ]}
              />
            </Card>
          </Col>
          <Col xs={12} md={6} lg={8}>
            <Card
              title="Nombre photos PixTransfer"
              className={"mt-10 mt-30-xl"}
            >
              <BarChart
                isLegend
                title=""
                height={460}
                labels={dateDefault.filter(
                  (date, idx) => checkIsWeekDefault[idx]
                )}
                digits={0}
                dataSet={this.state.newFolderUserPixTransfer.map((element) => ({
                  ...element,
                  data: element.data.filter(
                    (element, idx) => checkIsWeekDefault[idx]
                  ),
                }))}
              />
            </Card>
          </Col>
          <Col xs={12} md={6} lg={12}>
            <Card title="% dossiers PixTransfer" className={"mt-10 mt-30-xl"}>
              <BarChart
                isLegend
                title=""
                height={460}
                labels={dateDefault.filter(
                  (date, idx) => checkIsWeekDefault[idx]
                )}
                digits={0}
                format="percentage"
                dataSet={this.state.percentageFolderUserPixTransfer.map(
                  (element) => ({
                    ...element,
                    data: element.data.filter(
                      (element, idx) => checkIsWeekDefault[idx]
                    ),
                  })
                )}
                xAxes={[
                  {
                    beginAtZero: true,
                    gridLines: {
                      display: false,
                    },
                  },
                ]}
              />
            </Card>
          </Col>
          {this.context.isEvalCar ? (
            <>
              <Col xs={12} md={6} lg={4}>
                <Card
                  title="Nouveaux dossiers EvalCar"
                  className={"mt-10 mt-30-xl"}
                >
                  <BarChart
                    title=""
                    height={460}
                    labels={dateDefault.filter(
                      (date, idx) => checkIsWeekDefault[idx]
                    )}
                    digits={0}
                    dataSet={[
                      {
                        label: "Nouveaux dossiers",
                        type: "line",
                        data: this.state.newFolderEvalCar.filter(
                          (element, idx) => checkIsWeekDefault[idx]
                        ),
                        color: colors.blue[0],
                      },
                    ]}
                  />
                </Card>
              </Col>
              <Col xs={12} md={6} lg={8}>
                <Card
                  title="Nouveaux dossiers EvalCar"
                  className={"mt-10 mt-30-xl"}
                >
                  <BarChart
                    isLegend
                    title=""
                    height={460}
                    labels={dateDefault.filter(
                      (date, idx) => checkIsWeekDefault[idx]
                    )}
                    digits={0}
                    dataSet={this.state.newFolderUserEvalCar.map((element) => ({
                      ...element,
                      data: element.data.filter(
                        (element, idx) => checkIsWeekDefault[idx]
                      ),
                    }))}
                  />
                </Card>
              </Col>
              <Col xs={12} md={6} lg={12}>
                <Card title="% dossiers EvalCar" className={"mt-10 mt-30-xl"}>
                  <BarChart
                    isLegend
                    title=""
                    height={460}
                    labels={dateDefault.filter(
                      (date, idx) => checkIsWeekDefault[idx]
                    )}
                    min={0}
                    digits={0}
                    format="percentage"
                    dataSet={this.state.percentageFolderUserEvalCar.map(
                      (element) => ({
                        ...element,
                        data: element.data.filter(
                          (element, idx) => checkIsWeekDefault[idx]
                        ),
                      })
                    )}
                    xAxes={[
                      {
                        beginAtZero: true,
                        gridLines: {
                          display: false,
                        },
                      },
                    ]}
                  />
                </Card>
              </Col>
            </>
          ) : null}
          {this.context.isRedaxion ? (
            <Col xs={12}>
              <Card
                title="Nouveaux dossiers Rédaxion"
                className={"mt-10 mt-30-xl"}
              >
                <BarChart
                  title=""
                  height={460}
                  labels={dateDefault.filter(
                    (date, idx) => checkIsWeekDefault[idx]
                  )}
                  digits={0}
                  dataSet={[
                    {
                      label: "Nouveaux dossiers",
                      type: "line",
                      data: this.state.newFolderRedaxion.filter(
                        (element, idx) => checkIsWeekDefault[idx]
                      ),
                      color: colors.blue[0],
                    },
                  ]}
                />
              </Card>
            </Col>
          ) : null}
          <Col xs={12} lg={12}>
            <Card title="Courriers Maileva" className={"mt-10 mt-30-xl"}>
              <BarChart
                title=""
                height={460}
                labels={dateDefault.filter(
                  (date, idx) => checkIsWeekDefault[idx]
                )}
                digits={0}
                dataSet={[
                  {
                    label: "Courriers",
                    type: "line",
                    data: this.state.dataMaileva.filter(
                      (element, idx) => checkIsWeekDefault[idx]
                    ),
                    color: colors.blue[0],
                  },
                ]}
              />
            </Card>
          </Col>
          <Col xs={12} lg={6}>
            <Card title="Pièces O / V / B" className={"mt-10 mt-30-xl"}>
              <BarChart
                title=""
                height={460}
                labels={this.state.dataParts.months}
                digits={0}
                dataSet={[
                  ...(this.state.dataParts.Opisto
                    ? [
                        {
                          label: "Opisto",
                          type: "line",
                          data: this.state.dataParts.months.map(
                            (month) => this.state.dataParts.Opisto[month] || 0
                          ),
                          color: colors.blue[0],
                        },
                      ]
                    : []),
                  ...(this.state.dataParts.Valused
                    ? [
                        {
                          label: "Valused",
                          type: "line",
                          data: this.state.dataParts.months.map(
                            (month) => this.state.dataParts.Valused[month] || 0
                          ),
                          color: colors.green[0],
                        },
                      ]
                    : []),
                  ...(this.state.dataParts.Back2car
                    ? [
                        {
                          label: "Back2car",
                          type: "line",
                          data: this.state.dataParts.months.map(
                            (month) => this.state.dataParts.Back2car[month] || 0
                          ),
                          color: colors.orange[0],
                        },
                      ]
                    : []),
                  ...(this.state.dataParts.Capnor
                    ? [
                        {
                          label: "Capnor",
                          type: "line",
                          data: this.state.dataParts.months.map(
                            (month) => this.state.dataParts.Capnor[month] || 0
                          ),
                          color: colors.red[0],
                        },
                      ]
                    : []),
                ]}
              />
            </Card>
          </Col>
          <Col xs={12} lg={6}>
            <Card title="PQE" className={"mt-10 mt-30-xl"}>
              <BarChart
                title=""
                height={460}
                labels={this.state.dataPQE.months}
                digits={0}
                dataSet={Object.keys(this.state.dataPQE)
                  .filter((key) => key !== "months")
                  .map((key) => {
                    return {
                      label: key,
                      type: "line",
                      data: this.state.dataPQE.months.map(
                        (month) => this.state.dataPQE[key][month] || 0
                      ),
                      color: colors.blue[0],
                    };
                  })}
              />
            </Card>
          </Col>
          <Col xs={12}>
            <Card title="Utilisation annuelle" className={"mt-10 mt-30-xl"}>
              <Table isCSV>
                <Thead>
                  <Tr>
                    <Th>Région</Th>
                    <Th>nb. dossiers Evalcar</Th>
                    <Th>% dossiers Evalcar</Th>
                    <Th>nb. dossiers PixTransfer</Th>
                    <Th>% dossiers PixTransfer</Th>
                    <Th>nb. dossiers Rédaxion</Th>
                    <Th>% dossiers Rédaxion</Th>
                    <Th>% dossiers signature électronique</Th>
                    <Th>% signatures OK</Th>
                    <Th>nb. Maileva</Th>
                    <Th>% Maileva</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {this.state.dataRegions.map((element, idx) => (
                    <Tr>
                      <Th>{element.regionName}</Th>
                      <Td>{element.countEvalCar}</Td>
                      <Td>
                        {getPercentageValue(
                          element.countEvalCar / element.countGeneralEvalCar
                        )}
                      </Td>
                      <Td>{element.countPixTransfer}</Td>
                      <Td>
                        {getPercentageValue(
                          element.countPixTransfer /
                            element.countGeneralPixTransfer
                        )}
                      </Td>
                      <Td>{element.countRedaxion}</Td>
                      <Td>
                        {getPercentageValue(
                          element.countRedaxion / element.countGeneralRedaxion
                        )}
                      </Td>
                      <Td>
                        {getPercentageValue(
                          element.countElectronicSignature /
                            element.countGeneralEvalCar
                        )}
                      </Td>
                      <Td>
                        {getPercentageValue(
                          element.countElectronicSignatureOK /
                            element.countElectronicSignature
                        )}
                      </Td>
                      <Td>{element.countMaileva}</Td>
                      <Td>
                        {getPercentageValue(
                          element.countMaileva / element.countGeneralPixTransfer
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
